import React from 'react';
import styled from '@emotion/styled';

const StyledCard = styled.div`
  ${tw`relative flex flex-col shadow-md rounded-lg mb-3 mt-3 bg-beige text-lg`};
  padding: 0 1rem 1rem 1rem;
  ${p => p.first && tw`mt-12`}
  h1{
    ${tw`font-title text-xl`}
  }
  h3 {
    ${tw`mb-5 text-md `};
  }
  .citation{
    ${tw`italic`}
  }
  .author{
    &:before{
      content: '- '
    }
    ${tw`block text-sm pt-4`}
  }
`;

export default function Card({ title, children, className, first }) {
  return (
    <StyledCard className={className} first={first}>
      {title && <h1>{title}</h1>}
      {children}
    </StyledCard>
  );
}
