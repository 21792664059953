import React, {useState} from 'react'
import MapGL, {Marker} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from '@emotion/styled';
import LocationMarker from '../../images/icons/location-marker.svg';

const MapWrapper = styled.div`
    ${tw`h-64 w-full`}
`


export default function Map ({lat, lon, actorsLocation}) {

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '100%',
        latitude: lat,
        longitude: lon,
        zoom: 12,
    });
   
    return(
        <MapWrapper>
            <a target="_blank" href={`https://google.com/maps/search/?api=1&query=${lat},${lon}`}>
                <MapGL
                {...viewport}
                onViewportChange={nextViewport => setViewport(nextViewport)}
                mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                style={{pointerEvents: 'none'}}
                >
                    {(actorsLocation.length > 0) ? 
                        actorsLocation.map(({localisation}, i) => {
                            return(
                                <Marker key={i} longitude={localisation.lon} latitude={localisation.lat}>
                                    {
                                    (localisation.lon === lon && localisation.lat === lat) ?
                                        <LocationMarker 
                                            height="25px" 
                                            style={{transform: 'translateX(-12.5px) translateY(-12.5px)', fill: '#d0ff02'}} 
                                        />
                                        :
                                        <LocationMarker 
                                            height="20px" 
                                            style={{transform: 'translateX(-10px) translateY(-10px)', fill: 'white'}} 
                                        />
                                    }
                                    
                                </Marker>
                            )
                        }) : 
                    
                        <Marker longitude={lon} latitude={lat}>
                            <LocationMarker 
                                height="25px"
                                style={{transform: 'translateX(-12.5px) translateY(-12.5px)', fill: '#d0ff02'}}
                            />
                        </Marker>
                    
                    }
                </MapGL>
            </a>
        </MapWrapper>
    )
}

