import React from 'react'
import ArrowLeft from '../../images/icons/arrow-left.svg'
import styled from '@emotion/styled'
import {Link} from 'gatsby'

const NavWrapper = styled.span`
  ${tw`absolute p-2`}
  top: 0;
  a{
      ${tw`flex`}
  }
  span{
      ${tw`pl-2 font-bold`}
  }
  ${p => p.right && `right: 0;`}
`

export default function NavLink({to, content, right, noArrow}){
    return(
        <NavWrapper right={right}>
            <Link to={to}>{!noArrow && <ArrowLeft height="20"/>} <span>{content ?? "Retour"}</span> </Link>
        </NavWrapper>
    )
}