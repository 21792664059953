import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
    ${tw`min-h-screen flex flex-col py-0 px-2 justify-center relative`}
`
export default function FullHeightWrapper({children}){
    return(
        <Wrapper>
            {children}
        </Wrapper>
    )
}