import styled from '@emotion/styled'
import Layout from './layout'
import SEO from './seo'
import ContentWrapper from './contentWrapper'
import Card from './card'
import Map from './map'
import FullHeightWrapper from './fullHeightWrapper'
import NavLink from './navLink'

const Question = styled.h1`
    ${tw`text-3xl font-title flex flex-col`}
`

const Hint = styled.span`
    ${tw`text-xs italic`}
`

const SubTitle = styled.h2`
    ${tw`text-xl font-title`}
`

const List = styled.ul`
    && li{
        ${tw`list-disc list-inside`}
    }
    & > li{
        ${tw`list-disc list-inside`}
    }
`

const FlexWrapper = styled.div`
  ${tw`flex flex-wrap overflow-hidden items-center`}
`

const HalfCard = styled.div`
    ${tw`w-1/2 overflow-hidden`}
    a{
        ${p => p.empty && tw`text-gray cursor-default`}
        ${tw`h-12 mr-2 my-2 p-2 bg-beige block rounded-lg font-bold text-md shadow-md`}
    }
`
export { 
    Layout, 
    SEO, 
    ContentWrapper, 
    Card, 
    Map, 
    FullHeightWrapper, 
    Question, 
    HalfCard, 
    NavLink, 
    Hint,
    List,
    FlexWrapper,
    SubTitle
};
